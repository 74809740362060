import React from 'react';
import {
  Box,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuPopper from '../atoms/MenuPopper';
import IconHome from '../../static/images/icon-home.svg';
import IconKey from '../../static/images/icon-key.svg';
import IconSwitch from '../../static/images/icon-switch.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      height: '50px',
      display: 'flex',
      columnGap: '15px',
    },
    '& .opening-process': { color: theme.palette.info.main },
    '& .switching-process': { color: theme.palette.success.main },
  },
  divider: {
    width: '89%',
    height: '1px',
    backgroundColor: theme.palette.action.disabledBackground,
    margin: '0 16px',
  },
}));

const HeaderDropdownMenu = ({ menuAnchor, setMenuAnchor }) => {
  const classes = useStyles();

  return (
    <MenuPopper menuAnchor={menuAnchor} setMenuAnchor={setMenuAnchor} className={classes.root}>
      <MenuItem
        component="a"
        href={`${process.env.GATSBY_DYNAMO_LOGIN}`}
      >
        <img src={IconHome} alt="Ícone plataforma Conube" />
        Acessar plataforma
      </MenuItem>

      <Box className={classes.divider} />

      <MenuItem
        component="a"
        href={`${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/login`}
        className="opening-process"
      >
        <img src={IconKey} alt="Ícone plataforma de Abertura de Empresas" />
        Processo de Abertura
      </MenuItem>

      <MenuItem
        component="a"
        href="https://trocarcontador.conube.com.br/login"
        className="switching-process"
      >
        <img src={IconSwitch} alt="Ícone plataforma de Trocar de Contador" />
        Processo de Trocar de contador
      </MenuItem>
    </MenuPopper>
  );
};

HeaderDropdownMenu.propTypes = {
  menuAnchor: PropTypes.string.isRequired,
  setMenuAnchor: PropTypes.string.isRequired,
};

export default HeaderDropdownMenu;
